if(location.hash === '#contact'){
    document.querySelector('#contact-form').classList.add('is-opened');
}

var popupOpen = document.querySelectorAll('.popup-open');
for(var i = 0; i < popupOpen.length; i++){
    popupOpen[i].addEventListener('click',function(e){
        var active = document.querySelector('.popup.is-opened');
        if(typeof(active) != 'undefined' && active != null){
            active.classList.remove('is-opened');
        }
        document.querySelector(this.getAttribute('data-popup')).classList.add('is-opened');
    },false);
}

var popupClose = document.querySelectorAll('.popup-close');
for(var i = 0; i < popupClose.length; i++){
    popupClose[i].addEventListener('click',function(e){
        this.parentNode.parentNode.classList.remove('is-opened');
    },false);
}